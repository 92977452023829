/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
 */

/* * {
  font-family: poppins;
} */
.navlogo {
  max-height: 50px;
}
.media:hover {
  transform: translateY(-2px);
  background-color: #2e2e2e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.media:hover * {
  color: #fff;
}

/* .custom-card {
  height: 300px;
} */

.fixedscrollable{
  height: 400px; 
  overflow-y: scroll;
}

.ccenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.old-book {
  background-color: #fffaf1; 
}

/* CSS for the typing animation */
@keyframes typing {
  from {
    width: 0;
  }
}

/* Adjust the typing speed as needed */
.is-typing {
  /* animation: typing 0.2s steps(40, end); */
  white-space: pre-wrap; /* Preserve line breaks and wrap the text */
  overflow: hidden;
}

.is-formatted {
  white-space: pre-wrap; /* Preserve line breaks and wrap the text */
  overflow: hidden;
}


/* Hide the default scrollbar for the targeted element */
.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #ffecc8;	
	background-image: -webkit-linear-gradient(45deg,
	                                          rgba(255, 255, 255, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(255, 255, 255, .2) 50%,
											  rgba(255, 255, 255, .2) 75%,
											  transparent 75%,
											  transparent)
}   
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}
/* .generated-article::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 1em;
  margin-left: 5px;
  vertical-align: bottom;
  animation: typing 1s steps(20, end) infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }

  
}
.typing-cursor {
  display: inline-block;
  width: 0.5em;
  height: 1em;
  margin-left: 5px;
  vertical-align: bottom;
  animation: blink 1s steps(1) infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
} */

/* Questionnaire*/
/* Quest.css */
.options button {
  min-width: 180px; /* Set minimum width for buttons */
  margin: 5px; /* Add spacing between buttons */
  font-size: 18px; /* Increase font size for buttons */
}

.progress-bar-container {
  position: relative;
  width: 100%;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 12px; /* Adjust the height of the progress bar */
  background-color: #ccc; /* Color of the progress bar track */
  transition: width 0.5s ease; /* Transition for width changes */
  border-radius: 6px; /* Rounded edges */
}
.progress-bar-wrapper {
  position: relative;
  width: 100%;
  height: 12px; /* Adjust the height of the progress bar container */
  background-color: #ececec; /* Background color for the reference */
  border-radius: 6px; /* Rounded edges */
}

.progress-fill {
  height: 100%;
 
  background: #ee522f;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #f59541, #E94057, #f14242);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #f59541, #E94057, #f14242); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 6px; /* Rounded edges */
}

/* .is-custom-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.is-wide-enough {
  min-width: 180px; /* Set minimum width for buttons */
}

.subject-image {
  width: 64px;
  height: 64px;
  overflow: hidden;
}

.subject-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* admin */
.highlight {
  color: rgb(255, 68, 0);
  font-weight: bold;
}

/* ContentGentSetup*/
.scrollable-content {
  max-height: 220px;
  min-height: 100px;
  overflow-y: scroll;
}

.scrollable-content::-webkit-scrollbar {
  width: 8px;
}

.scrollable-content::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 5px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: #614f7c;
  border-radius: 5px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background-color: #4a3a61;
}

.is-expanded {
  height: 100%;

}

/* fot mainsection active tab */
.tabs.is-boxed li.is-active a {
  color: #125488;
}

.no-scroll {
  overflow: hidden;
}

.media {
  transform: translateY(0);
  cursor: pointer;
  box-shadow: 0 0 0 2px transparent;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-radius: 10px;
}

.media.selected {
  background-color: #ee5050;
  color: #fff;
}

.media.selected * {
  color: #fff;
}

.scrollable-fixed {
  height: 465px;
  overflow-y: scroll;
}

.scrollable-fixed::-webkit-scrollbar {
  display: none;
}
