 .cbox {
   min-height: 100vh;
   position: relative;
   overflow-x: hidden;
   overflow-y: hidden;
   background: linear-gradient(to bottom, #284bd6, #0b62a9);
 }


/* .container {
  height: 100%;
} */


.mlogo {
  color: white;
  /* make color gradiant*/
  background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(121,9,119,1) 35%, rgb(243, 52, 52) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 22px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: 600;
  padding: 40px 0 0 35px !important;
}
.circle-one {
  height: 500px;
  width: 500px;
  border-radius: 50%;
  background: #0d69b6;
  position: absolute;
  bottom: -35%;
  left: -80px;
}

.circle-two {
  height: 450px;
  width: 450px;
  border-radius: 50%;
  background: #8A2387;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #F27121, #E94057, #8A2387);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to top, #F27121, #E94057, #8A2387); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  position: absolute;
  top: -20%;
  right: -50px;
}

.square {
  height: 500px;
  width: 700px;
  border-radius: 15px;
  background-color: #047dbe;
  position: absolute;
  bottom: -40%;
  right: -5px;
}

.main-content {

  display: flex;
  gap: 200px;
}

.first-part {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 120px 50px 0 150px;
  z-index: 1;
}

.first-part h2 {
  color: white;
  text-align: center;
  max-width: 450px;
  font-weight: 600;
  font-size: 40px;
}

.first-part p {
  color: white;
  max-width: 450px;
  text-align: center;
}

.circles {
  display: flex;
  gap: 30px;
}
.sml-circle-1 {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: #f5555a;
}
.sml-circle-2 {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: #f2b063;
}
.sml-circle-3 {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: #4ce77f;
}
.second-part {
  z-index: 1;
}

.mform {
  background-color: white;
  width: 420px;
  padding: 40px 50px;
  border-radius: 12px;
}
.mform h1 {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
}

.google {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  height: 40px;
  width: auto;
  background-color: #fef1f1;
  border-radius: 15px;
  cursor: pointer;
}

.google-signin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: auto;
  background-color: #fef1f1;
  border-radius: 15px;
  cursor: pointer;
}

.inputs {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.inputs label {
  font-size: 13px;
  font-weight: 500;
  color: #424955;
}

.inputs input {
  background-color: #f3f4f6;
  border-radius: 20px;
  padding: 5px;
  border-style: none;
  padding-left: 15px;
}

input::placeholder {
  color: #c3c7cf;
  padding-left: 10px;
  font-size: 14px;
}

.bt {
  margin-top: 20px;
  padding: 10px 0;
  border-radius: 20px;
  border-style: none;
  background-color: #eb6769;
  color: white;
  transition: box-shadow 0.3s ease-in-out;
}

.bt:hover {
  color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.log-p {
  text-align: center;
  font-size: smaller;
}


.password-input {
  position: relative;
}

.password-input input {
  width: 100%;
}

.password-input i {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

#signInDivG {
  display: flex;
  justify-content: center;
  align-items: center;

}
.iframe-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.iframe-wrapper iframe {
  width: 100%;
  height: auto;
  border: 0;
}


@media only screen and (max-width: 600px) {
  .circle-one {
    height: 250px;
    width: 250px;
    position: absolute;
    bottom: -90px;
    left: -70px;
  }
  .circle-two {
    height: 287px;
    width: 262px;
  }
  .square {
    height: 250px;
    width: 250px;
    position: absolute;
    bottom: -90px;
    right: -50px;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px !important;
  }

  .first-part {
    margin: auto;
    display: flex;
    padding: 50px 0;
    justify-content: center;
    align-items: center;
  }
  .sml-circle-3,
  .sml-circle-1,
  .sml-circle-2 {
    height: 90px;
    width: 90px;
  }
}

.placeholder-input {
  border: none;
  background-color: transparent;
}
.placeholder-input::placeholder {
  color: #39877a;
  font-weight: 500;
}
.error-message {
  color: red;
  font-weight: 500;
  margin-top: 10px;
} /* */