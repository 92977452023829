.container-subscription {
  padding: 5rem 15rem;
  min-height: 100vh;
  height: 100%;
  /* background-color: #e5ebed; */
  background-image: url('../../public/assets/images/blob-scene.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
}

.box-container {
  width: 80%;
  height: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 
  border-radius: 30px;
  padding: 30px;
}

.cardd-container {
  display: flex;
  gap: 25px;
  height: 100%;
}
.main-card {
  background-color: #047dbe;
  padding: 30px;
  min-height: 574px;
  max-height: 700px;
  border-radius: 15px;
  flex: 0.7;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.main-card p {
  font-size: 14px;
  color: white;
}

.main-card h1 {
  font-size: 32px;
  color: white;
  margin-bottom: -7px;
  font-weight: 400;
}

.main-card h2 {
  font-size: 32px;
  color: white;
  font-weight: 600;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.second-card {
  height: 100%;
  
  flex: 1;
}
.start-now {
 min-height: 80px;
}

.card-bodyy {
  background: #ee522f;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #f59541, #E94057, #f14242);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #f59541, #E94057, #f14242); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  min-height: 490px;
  border-radius: 15px;
  padding: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}

.card-bodyy:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-4px);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.second-card h1 {
  margin-bottom: -7px;
  font-weight: 400;
  font-size: 28px;
}
.second-card h2 {
  margin-bottom: -7px;
  font-weight: 400;
  font-size: 28px;
}
.span-free {
  color: black;
  font-weight: 600 !important;
}

.lite-item {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.free-item {
  font-size: 22px;
  margin: 0;
  text-align: end;
}
.restrection-span {
  font-size: 12px;
  color: #ffffff;
  margin: 0;
}

.lane {
  width: 72%;
  height: 6%;
  margin-top: 7px;
  background-color: #ffffff;
}
.lane-2 {
  width: 72%;
  height: 6%;
  margin-top: 7px;
  background-color: white;
}
.container-subscription .fa-check {
  font-size: 14px;
  color: #ffffff;
}

.plans-item {
  margin-top: 15px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  line-height: normal !important ;
  font-weight: 600;
}

.paragraphe-item {
  font-size: medium;
  text-align: left;
}
.paragraphe-item strong {
  color: #ffffff;
  font-size: medium;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.btn-ftre {
  margin-top: 50px;
  background-color: #111111;
  color: rgb(255, 252, 222);
  font-size: 15px;
  border-radius: 10px;
  border-style: none;
  padding: 13px 15px;
  font-weight: 600;
  transition: box-shadow 0.3s ease-in-out;
}

.btn-ftre:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.btn-ftre:active {
  transform: translateY(1px);
  box-shadow: none;
}

.third-card {
  height: 100%;

  flex: 1;
}

.third-card p {
  color: white;
}

.third-card h1 {

  color: white;
  margin-bottom: -7px;
  font-weight: 400;
}

.third-card h2 {
  color: white;
}

.third-card span {
  color: white;
}

.card-bodyyy {
  background-color: #047dbe;
  min-height: 490px;
  border-radius: 15px;
  padding: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}

.card-bodyyy:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-4px);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.btn-ftree {
  margin-top: 66px;
  color: #0b62a9;
  background-color: white;
  font-size: 14px;
  border-radius: 10px;
  border-style: none;
  padding: 13px 15px;
  font-weight: 600;
}
.btn-ftree:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
} 
.btn-ftree:active {
  transform: translateY(1px);
  box-shadow: none;
}

.s2 {
  color: white;
}

.text-right {
  text-align: right;
}

.fwt-bolder {
  font-weight: 600;
  font-size: 14px;
}
.fs-x {
  color: #ff6155;
}

.price-item {
  font-size: 22px;
  text-decoration: line-through;
  color: gray;
  margin-right: 0.5rem;
}

/* Media Queries */
@media only screen and (max-width: 600px) {
  .container-subscription {
    width: 100%;
    height: 100%;
    padding: 20px 0 20px 22px !important;
    background-color: white;
  }
  .box-container {
    max-width: 600px;
    width: 100%;
    background-color: transparent;
  }

  .cardd-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    height: 100%;
  }
  .main-card {
    height: auto;
  }
  .second-card {
    height: auto;
  }
  .third-card {
    height: auto;
  }
  .card-bodyyy {
    height: auto;
  }
  .card-bodyy {
    height: auto;
  }
}
