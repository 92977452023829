.card-container {
  padding: 10px;
  background-color: #f8f9fa;
  display: flex;
  gap: 25px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
}

.card-container h5 {
  font-size: 1.2rem !important;
  text-align: center;
}

.card-container p {
  font-size: 13px;
  text-align: center;
}

.card-container.focused {
  background-color: #ee787a;
  color:white;
}
