/* Styles for the subscription details page */
.bdy{
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ee787a;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f7adbd' fill-opacity='0.4' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
    background-size: 65%;
    background-position: bottom;
    padding: 2vw 4vw;
    min-height: 100vh;
}

  .container-c {
    max-width: 800px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.201);
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 6vw auto 0;
 
  }
  .card-c {
    padding: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .card-c h1 {
    font-size: 32px;
    margin-bottom: 20px;
    
  }
  .card-c p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .card-c .price {
    font-size: 25px;
    margin-bottom: 20px;
    text-decoration: line-through;
  }
  .card-c .free {
    font-size: 18px;
    margin-bottom: 10px;
    color: green;
    font-weight: bold;
  }
  .card-c .early-access {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card-c .early-access label {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .card-c .early-access input[type="text"] {
    width: 200px;
    height: 40px;
    font-size: 14px;
    padding: 7px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .card-c .early-access button {
    width: 200px;
    height: 40px;
    font-size: 16px;
    color: #fff;
    background-color: #ff5a5f;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .card-c .early-access button:hover {
    background-color: #ff4247;
  }
  /* Highlight the selected subscription */
  .selected-subscription {
    background-color: #ff5a5f;
    color: #fff;
    padding: 3px 10px;
    border-radius: 20px;
  }

  .error-c {
    color: red;
    font-weight: bold;
    margin-top: 10px;
  }